import {useState} from "react";
import apiService from "./../services/apiService";
import {useLoading} from "../context/loadingContext";

const useApi = () => {
    const [data, setData] = useState(null);
    const [error, setError] = useState(null);
    const { showLoading, hideLoading } = useLoading();

    const request = async ({ url, method = "get", data = {}, headers = {}, withCredentials = true }) => {
        showLoading();
        try {
            const response = await apiService({
                url,
                method,
                data,
                headers,
                withCredentials
            });
            setData(response.data);
            return response;
        } catch (err) {
            console.log(err);
            setError(err);
            return err.response;
        } finally {
            hideLoading();
        }
    };

    return { data, error, request };
};

export default useApi;
