import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useApi from "./../../hooks/useApi";

const ValidateEmail = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { request, error, loading } = useApi();

    useEffect( () => {
        const token = searchParams.get("token");
        if (!token) {
            navigate("/", { state: { message: "No token provided." } });
            return;
        }

        const verifyEmail = async () => {
            const response = await request({
                url: `/verify-email?token=${token}`,
                method: "post"
            });

            if (response) {
                navigate("/", { state: { message: "Email verified successfully.", severity: "success" } });
            } else {
                navigate("/", { state: { message: "There was an error with the verification.", severity: "error" } });
            }
        };

        verifyEmail().catch((error) => {
            console.log(error);
            navigate("/", { state: { message: "There was an error with the verification.",  severity: "error" } });
        });
    }, [searchParams, navigate, request]);

    if (loading) return <p>Verifying...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return <div>Verifying email...</div>;
};

export default ValidateEmail;
