import AccountabilitySettings from "./onboarding/AccountabilitySettings";
import CommunicationSettings from "./onboarding/CommunicationSettings";
import PersonalInfo from "./onboarding/PersonalInfo";
import Payment from "./onboarding/Payment";
import Dashboard from "./dashboard/Dashboard";
import Profile from "./dashboard/Profile";
import Subscription from "./dashboard/Subscription";
import ResetPassword from "./auth/ResetPassword";
import Welcome from "./onboarding/Welcome";
import Completed from "./onboarding/Completed";
import Logout from "./auth/Logout";
import withAuth from "./hoc/withAuth";
import ConsequencesSettings from "./onboarding/ConsequencesSettings";

const ProtectedDashboard = withAuth(Dashboard);
const ProtectedProfile = withAuth(Profile);
const ProtectedSubscription = withAuth(Subscription);
const ProtectedPersonalInfo = withAuth(PersonalInfo);
const ProtectedAccountabilitySettings = withAuth(AccountabilitySettings);
const ProtectedCommunicationSettings = withAuth(CommunicationSettings);
const ProtectedPayment = withAuth(Payment);
const ProtectedResetPassword = withAuth(ResetPassword);
const ProtectedWelcome = withAuth(Welcome);
const ProtectedCompleted = withAuth(Completed);
const ProtectedLogout = withAuth(Logout);
const ProtectedConsequencesSettings = withAuth(ConsequencesSettings);

export {
    ProtectedDashboard,
    ProtectedProfile,
    ProtectedSubscription,
    ProtectedPersonalInfo,
    ProtectedAccountabilitySettings,
    ProtectedCommunicationSettings,
    ProtectedPayment,
    ProtectedResetPassword,
    ProtectedWelcome,
    ProtectedCompleted,
    ProtectedLogout,
    ProtectedConsequencesSettings
};
