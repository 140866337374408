import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {Button} from "@mui/joy";
import { Radio, FormControlLabel, RadioGroup } from "@mui/material";
import SplitScreenLayout from "../../layouts/SplitScreenLayout";
import InputField from "../../components/InputField";
import {Link, useNavigate} from "react-router-dom";
import useApi from "../../hooks/useApi";

const CommunicationSettings = () => {
    const navigate = useNavigate();
    const { request } = useApi();

    const formik = useFormik({
        initialValues: {
            medium: "",
            email: "",
            phone: "",
            otherMedium: "",
            otherMediumInfo: "",
        },
        validationSchema: Yup.object({
            medium: Yup.string().required("Required"),
            email: Yup.string().when("medium", {
                is: "email",
                then: () => Yup.string().email("Invalid email address").required("Required"),
                otherwise: () => Yup.string().notRequired(),
            }),
            phone: Yup.string().when("medium", {
                is: "whatsapp",
                then: () => Yup.string().required("Required"),
                otherwise: () => Yup.string().notRequired(),
            }),
            otherMedium: Yup.string().when("medium", {
                is: "others",
                then: () => Yup.string().required("Required"),
                otherwise: () => Yup.string().notRequired(),
            }),
            otherMediumInfo: Yup.string().when("medium", {
                is: "others",
                then: () => Yup.string().required("Required"),
                otherwise: () => Yup.string().notRequired(),
            }),
        }),
        onSubmit: async (values) => {
            const communicationMedium = values.medium === "others" ? values.otherMedium : values.medium;
            const communicationMediumInfo = values.medium === "email" ? values.email : values.medium === "whatsapp" ? values.phone : values.otherMediumInfo;

            try {
                const response = await request({
                    url: "/accountability",
                    method: "post",
                    data: {
                        communicationMedium,
                        communicationMediumInfo
                    },
                });

                if (response?.status === 200) {
                    const response = await request({
                        url: "/update-status",
                        method: "post",
                        data: {
                            onboardingStatus: "payment",
                        },
                    });

                    if (response?.status === 200) {
                        navigate("/onboarding/consequences");
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
    });

    const handleRadioChange = (event) => {
        formik.setFieldValue(event.target.name, event.target.value);
    };
    
    return (
        <SplitScreenLayout title="What is the preferred communication channel you want us to contact you on?">
            <div className="top-button">
                <Link to={"/onboarding/accountability"} className="margin-t20 link-navigation">Go back</Link>
            </div>
            <form onSubmit={formik.handleSubmit} className="form-container">
                <RadioGroup
                    name="medium"
                    value={formik.values.medium}
                    onChange={handleRadioChange}
                >
                    <FormControlLabel value="email" control={<Radio/>} className="radio-button radio-label"
                        label="Email"/>
                    {formik.values.medium === "email" && (
                        <div className="sub-option">
                            <small>Email</small>
                            <InputField
                                name="email"
                                type="email"
                                className="margin-b20"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                error={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                            />
                        </div>

                    )}
                    <FormControlLabel value="whatsapp" control={<Radio/>} className="radio-button radio-label"
                        label="WhatsApp"/>
                    {formik.values.medium === "whatsapp" && (
                        <div className="sub-option">
                            <small>Phone number</small>
                            <InputField
                                name="phone"
                                type="text"
                                className="margin-b20"
                                onChange={formik.handleChange}
                                value={formik.values.phone}
                                error={formik.touched.phone && formik.errors.phone ? formik.errors.phone : null}
                            />
                        </div>
                    )}
                    <FormControlLabel value="others" control={<Radio/>} className="radio-button radio-label"
                        label="Others"/>
                    {formik.values.medium === "others" && (
                        <div className="sub-option">
                            <small>Specify your preferred chat app</small>
                            <InputField
                                name="otherMedium"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.otherMedium}
                                error={formik.touched.otherMedium && formik.errors.otherMedium ? formik.errors.otherMedium : null}
                                className="margin-b20"
                            />

                            <small>Chat app username or phone number</small>
                            <InputField
                                name="otherMediumInfo"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.otherMediumInfo}
                                error={formik.touched.otherMediumInfo && formik.errors.otherMediumInfo ? formik.errors.otherMediumInfo : null}
                            />
                        </div>
                    )}
                </RadioGroup>
                <Button type="submit" size="lg" className="form-button full-width margin-t20">Next</Button>
            </form>
        </SplitScreenLayout>
    );
};

export default CommunicationSettings;