import React, {useEffect, useState} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import SidebarLayout from "../../layouts/SidebarLayout";
import { Box, Typography, Button, Modal, ModalDialog, Snackbar, Alert } from "@mui/joy";
import useApi from "../../hooks/useApi";
import moment from "moment-timezone";

const Subscription = () => {
    const [openModal, setOpenModal] = useState(false);
    const [modalAction, setModalAction] = useState(""); // To track which action triggered the modal
    const [currentPlan, setCurrentPlan] = useState(""); // State to track current plan
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");

    const [nextPayment, setNextPayment] = useState(""); // State to track next payment date
    const [trialEnd, setTrialEnd] = useState(""); // State to track trial end date
    const [status, setStatus] = useState(""); // State to track subscription status
    const [plan, setPlan] = useState(null); // State to track plan details

    const { request } = useApi();

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleSubmit = async (plan) => {
        try {
            const response = await request({
                url: "/payment",
                method: "post",
                data: {plan, oldUser: true},
            });

            if (response?.status === 302) {
                window.location.href = response.data.url;
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchUserData = async () => {
        try {
            const response = await request({
                url: "/user",
                method: "get"
            });

            if (response.status === 200) {
                const nextPaymentDate = moment(response.data.next_payment_date).format("MMMM Do YYYY");
                const trialEndDate = moment(response.data.trial_end_date).format("MMMM Do YYYY");
                setPlan(response.data.subscription_id);
                setCurrentPlan(response.data.payment_product);
                setNextPayment(nextPaymentDate);
                setTrialEnd(trialEndDate);
                setStatus(response.data.payment_status);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        fetchUserData().catch((error) => {
            console.error("Error fetching user data:", error);
        });
    }, []);

    const formik = useFormik({
        initialValues: {
            changePlan: false
        },
        validationSchema: Yup.object({
            changePlan: Yup.boolean()
        }),
        onSubmit: () => {
            if (modalAction === "changePlan") {
                changePlan();

            } else if (modalAction === "cancelSubscription") {
                cancelSubscription();
            }
            setOpenModal(false);
        },
    });

    const changePlan = async () => {
        try {
            const response = await request({
                url: `/user/subscription/${plan}`, // Adjust the endpoint as needed
                method: "put",
                data: {
                    plan: newPlan
                }
            });

            if (response.status === 200) {
                setSnackbarMessage("Plan changed successfully.");
                setSnackbarSeverity("success");
                setCurrentPlan(newPlan);
            }
        } catch (error) {
            setSnackbarMessage("Error changing plan. Please try again.");
            setSnackbarSeverity("error");
        }
    };

    const cancelSubscription = async () => {
        try {
            const response = await request({
                url: `/user/subscription/${plan}`, // Adjust the endpoint as needed
                method: "delete"
            });

            if (response.status === 200) {
                setSnackbarMessage("Subscription cancelled successfully.");
                setSnackbarSeverity("success");
                setCurrentPlan("none");
            }
        } catch (error) {
            setSnackbarMessage("Error cancelling plan. Please try again.");
            setSnackbarSeverity("error");
        }
    };

    const handleOpenModal = (action) => {
        setModalAction(action);
        setOpenModal(true);
    };

    const isYearlyPlan = currentPlan === "yearly";
    const newPlan = isYearlyPlan ? "monthly" : "yearly";
    const price = isYearlyPlan ? "$19.99" : "$167.88";

    return (
        <SidebarLayout>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }} >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} elevation={6} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Box sx={{maxWidth: 500, mx: "auto", minHeight: "calc(96vh - 70px)", marginTop: "70px"}}>
                <Typography level="h4" sx={{mb: 3}}>
                    Subscription
                </Typography>

                {status !== "active" && status !== "trialing" ? (
                    <>
                        <Typography level="body1" sx={{mb: 3}}>
                            <span>
                            You are currently on a <strong>free</strong> plan.<br/>
                                <strong>Upgrade</strong> to unlock all features.
                            </span>
                        </Typography>
                        <section id="pricing">
                            <div className="pricing-table" style={{ gridTemplateColumns: "repeat(1, 1fr)" }}>
                                <div className="pricing-plan">
                                    <span className="chip chip-green">7 day trial period</span>
                                    <h1 className="pricing-title">Monthly</h1>
                                    <h2 className="pricing-header">$19.99 / month</h2>
                                    <ul className="pricing-features">
                                        <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Access to
                        All Features
                                        </li>
                                        <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Unlimited
                        Task Creation
                                        </li>
                                        <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Personalized
                        Accountability Options
                                        </li>
                                        <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Real Human
                        Support
                                        </li>
                                        <li className="pricing-features-item"><span className="checkbox">&#10004;</span>No
                        Commitment
                                        </li>
                                    </ul>
                                    <span className="pricing-price">Monthly (pay every month $19.99)</span>
                                    <Button type="button" size="lg" onClick={() => handleSubmit("monthly")}
                                        className="form-button full-width margin-t20">Select</Button>
                                </div>
                                <div className="pricing-plan">
                                    <span className="chip chip-green">7 day trial period</span><span className="chip chip-red">30% Discount</span>
                                    <h1 className="pricing-title">Yearly</h1>
                                    <h2 className="pricing-header">$13.99 / month</h2>
                                    <ul className="pricing-features">
                                        <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Access to
                        All Features
                                        </li>
                                        <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Unlimited
                        Task Creation
                                        </li>
                                        <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Personalized
                        Accountability Options
                                        </li>
                                        <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Real Human
                        Support
                                        </li>
                                        <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Commit to
                        Success
                                        </li>
                                    </ul>
                                    <span className="pricing-price">Yearly (pay every year once $167.88)</span>
                                    <Button type="button" size="lg" onClick={() => handleSubmit("yearly")}
                                        className="form-button full-width margin-t20">Select</Button>

                                </div>
                            </div>
                        </section></>
                ) : (
                    <>
                        <Typography>
                            <span>
                            You are currently on a <strong>{currentPlan.charAt(0).toUpperCase() + currentPlan.slice(1)}</strong> plan.<br/>
                                { status === "trialing" && (
                                    <span>
                                    Your trial ends on <strong>{trialEnd}</strong>.<br/>
                                    </span>
                                )}
                            Your next payment is on <strong>{nextPayment}</strong>.
                            </span>
                        </Typography>
                        <ul className="pricing-features">
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Access to
                    All Features
                            </li>
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Unlimited
                    Task Creation
                            </li>
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Personalized
                    Accountability Options
                            </li>
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Real Human
                    Support
                            </li>
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>{
                                isYearlyPlan ? "Commit to success" : "No Commitment"
                            }</li>
                        </ul>
                        <Button
                            className="form-button full-width margin-t20"
                            size="lg"
                            sx={{mb: 2, width: "100%"}}
                            onClick={() => handleOpenModal("changePlan")}
                        >
        Change to {newPlan.charAt(0).toUpperCase() + newPlan.slice(1)} Plan
                        </Button>
                        <Button
                            variant="plain"
                            size="sm"
                            color="gray"
                            sx={{mb: 2, width: "100%"}}
                            onClick={() => handleOpenModal("cancelSubscription")}
                        >
        Cancel Subscription
                        </Button>

                        {/* Modal for confirmation */}
                        <Modal open={openModal} onClose={() => setOpenModal(false)}>
                            <ModalDialog>
                                {modalAction === "changePlan" ? (
                                    <Typography level="body1">
                    Are you sure you want to change your plan
                    to <strong>{newPlan.charAt(0).toUpperCase() + newPlan.slice(1)}</strong> for {price}?
                                    </Typography>
                                ) : (
                                    <Typography level="body1">
                    Are you sure you want to cancel your subscription?
                                    </Typography>
                                )}
                                <Button
                                    size="lg"
                                    sx={{mt: 2}}
                                    onClick={formik.handleSubmit}
                                    className="form-button margin-r10"
                                >
                Confirm
                                </Button>
                                <Button
                                    variant="outlined"
                                    size="lg"
                                    onClick={() => setOpenModal(false)}
                                >
                Cancel
                                </Button>
                            </ModalDialog>
                        </Modal></>
                )}


            </Box>
        </SidebarLayout>
    );
};

export default Subscription;
