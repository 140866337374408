import React, {useEffect, useState} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FormControlLabel, RadioGroup, Radio, FormGroup, OutlinedInput } from "@mui/material";
import {
    Modal,
    ModalDialog,
    Typography,
    Button,
    Checkbox,
    ModalClose,
    Select,
    Option,
    ToggleButtonGroup
} from "@mui/joy";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import InputField  from "./InputField";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles
import Grid from "@mui/joy/Grid";


const TaskModal = ({ open, handleClose, task = null, handleSubmit }) => {

    const [activeDays, setActiveDays] = useState([]);

    const formik = useFormik({
        initialValues: {
            title: task?.title || "",
            description: task?.description || "",
            importanceScore: task?.importance_score || "normal",
            dueDate: task?.due_date || null,
            isRecurring: task?.is_recurring || false,
            recurringFrequency: task?.recurring_frequency || null,
            repeatEvery: task?.repeat_every || 1,
            repeatType: task?.repeat_type || "day",
            repeatDays: task?.repeat_on_days || "",
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Required"),
            description: Yup.string(),
            importanceScore: Yup.string().required("Required"),
            dueDate: Yup.date().nullable(),
            isRecurring: Yup.boolean(),
            recurringFrequency: Yup.string().when("isRecurring", {
                is: true,
                then: () => Yup.string().required("Required"),
            }),
            repeatEvery: Yup.number().min(1).when("recurringFrequency", {
                is: "custom",
                then: () => Yup.number().required("Required"),
            }),
            repeatType: Yup.string().when("recurringFrequency", {
                is: "custom",
                then: () => Yup.string().required("Required"),
            }),
            repeatDays: Yup.string().when("recurringFrequency", {
                is: "custom",
                then: () => Yup.string().required("Required"),
            }),
        }),
        onSubmit: () => {
            handleClose();
        },
    });

    const handleEditorChange = (content, delta, source, editor) => {
        formik.values.description = editor.getHTML();
    };

    useEffect(() => {
        if (task) {
            formik.setValues({
                title: task.title,
                description: task.description,
                importanceScore: task.importance_score,
                dueDate: task.due_date || null,
                isRecurring: task.is_recurring,
                recurringFrequency: task.recurring_frequency,
                repeatEvery: task.repeat_every,
                repeatType: task.repeat_type,
                repeatDays: task.repeat_on_days,
            });
        } else {
            formik.resetForm();
        }
    }, [task, formik.setValues, formik.resetForm]);

    const handleDayToggle = (event, newDay) => {
        let updatedActiveDays = [...activeDays];
        let currentDays = formik.values.repeatDays;

        if (updatedActiveDays.includes(newDay)) {
            updatedActiveDays = updatedActiveDays.filter(day => day !== newDay);
            currentDays = currentDays.split(",").filter(day => day !== newDay).join(",");
        } else {
            updatedActiveDays.push(newDay);
            currentDays = currentDays ? `${currentDays},${newDay}` : newDay;
        }

        setActiveDays(updatedActiveDays);
        formik.values.repeatDays = currentDays;
    };

    return (
        <Modal open={open} onClose={handleClose} className="task-modal">
            <ModalDialog sx={(theme) => ({
                minWidth: "900px",
                padding: "0",
                [theme.breakpoints.down("md")]: {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    borderRadius: 0,
                    transform: "none",
                    maxWidth: "unset",
                    minWidth: "100%",
                    minHeight: "100vh",
                },
            })}>
                <ModalClose />
                <Typography style={{ paddingLeft: "15px", marginTop: "15px" }} variant="h3">{task ? "Edit Task" : "Add Task"}</Typography>

                <form style={{ borderTop: "1px solid #dedede"}} onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(formik.values);
                }}>
                    <Grid container sx={{ flexGrow: 1 }}>
                        <Grid md={8} xs={12} className="modal-content">
                            <small>Title</small>
                            <InputField
                                name="title"
                                type="text"
                                onChange={formik.handleChange}
                                value={formik.values.title}
                                error={formik.touched.title && formik.errors.title ? formik.errors.title : null}
                                className="margin-b20"
                                size="md"
                            />

                            <small>Description</small>
                            <ReactQuill style={{ height: "300px", paddingBottom: "50px"}} name="description" label="Description" className="margin-b20 margin-t10" theme="snow" value={formik.values.description} onChange={handleEditorChange} />

                        </Grid>
                        <Grid md={4} xs={12} className="modal-sidebar">
                            <FormGroup className="margin-t20">
                                <FormControlLabel
                                    control={<Checkbox checked={formik.values.isRecurring} onChange={formik.handleChange}
                                        name="isRecurring"/>}
                                    label={
                                        <small style={{marginLeft: "10px"}}>
                                            Is this a recurring task?
                                        </small>
                                    }
                                    sx={{marginBottom: "20px", marginLeft: "0px"}}
                                />
                            </FormGroup>
                            {formik.values.isRecurring ? (
                                <div className="margin-b20 app-datepicker">
                                    <RadioGroup
                                        name="recurringFrequency"
                                        value={formik.values.recurringFrequency}
                                        onChange={formik.handleChange}
                                        className="margin-b20"
                                    >
                                        <FormControlLabel value="daily" control={<Radio/>} label={<small>Daily</small>}/>
                                        <FormControlLabel value="weekday" control={<Radio/>}
                                            label={<small>Every weekday</small>}/>
                                        <FormControlLabel value="weekend" control={<Radio/>}
                                            label={<small>Weekends only</small>}/>
                                        <FormControlLabel value="custom" control={<Radio />} label={<small>Custom</small>}/>
                                    </RadioGroup>
                                    { formik.values.recurringFrequency === "custom" && (
                                        <div className="margin-b20">
                                            <small>
                                                <Grid container sx={{ flexGrow: 1 }} spacing={1}>
                                                    <Grid xs={4} style={{ marginTop: "5px"}}>Repeat every</Grid>
                                                    <Grid xs={3} style={{ marginTop: "-10px"}} >
                                                        <InputField value={formik.values.repeatEvery} size="sm" name="repeatEvery" type="number" onChange={formik.handleChange} />
                                                    </Grid>
                                                    <Grid xs={5}>
                                                        <Select size="sm" name="repeatType" value={formik.values.repeatType} onChange={formik.handleChange}>
                                                            <Option value="day">Day</ Option>
                                                            <Option value="week">Week</Option>
                                                            <Option value="month">Month</Option>
                                                        </Select>
                                                    </Grid>
                                                </Grid>
                                            </small>
                                            <small>
                                                Repeat on:
                                                <Grid container sx={{ flexGrow: 1 }} spacing={1}>
                                                    <ToggleButtonGroup size="sm" className="repeat-buttons" onChange={handleDayToggle}>
                                                        {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(day => (
                                                            <Button
                                                                key={day}
                                                                size="sm"
                                                                value={day}
                                                                variant={activeDays.includes(day) ? "contained" : "outlined"}
                                                            >
                                                                {day.substring(0, 2)}
                                                            </Button>
                                                        ))}
                                                    </ToggleButtonGroup>
                                                </Grid>
                                            </small>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="margin-b20 app-datepicker">
                                    <small>Due date / Deadline</small>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={["DateTimePicker"]}>
                                            <DateTimePicker
                                                name="dueDate"
                                                slotProps={{ textField: { size: "small" } }}
                                                disablePast
                                                renderInput={(params) => (
                                                    <OutlinedInput
                                                        {...params}
                                                        sx={{
                                                            "& .MuiInputBase-input": {
                                                                fontSize: "12px", // Example to set font size to a smaller value
                                                                height: "1.1875em", // Adjust height as needed
                                                                padding: "10px", // Adjust padding as needed
                                                                overflowY: "hidden",
                                                            }
                                                        }}
                                                    />
                                                )}
                                                value={formik.values.dueDate}
                                                onChange={value => formik.setFieldValue("dueDate", value)}/>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            )}

                            <small>Priority</small>
                            <Select
                                name="importanceScore"
                                value={formik.values.importanceScore}
                                onChange={(event) => {
                                    formik.setFieldValue("importanceScore", event.target.innerText.toLowerCase());
                                }}
                                error={formik.touched.importanceScore && formik.errors.importanceScore}
                                sx={{marginBottom: "20px"}}
                            >
                                <Option value="high">
                                    <ListItemDecorator><ArrowUpwardIcon sx={{color: "red"}}/></ListItemDecorator>
                                High
                                </Option>
                                <Option value="normal">
                                    <ListItemDecorator><ArrowRightAltIcon sx={{color: "green"}}/></ListItemDecorator>
                                Normal
                                </Option>
                                <Option value="low">
                                    <ListItemDecorator><ArrowDownwardIcon sx={{color: "blue"}}/></ListItemDecorator>
                                Low
                                </Option>
                            </Select>
                            <Button type="submit" size="md" className="form-button margin-t20 add-task-btn">
                                {task ? "Update Task" : "Create Task"}
                            </Button>
                        </Grid>

                    </Grid>

                </form>


            </ModalDialog>
        </Modal>
    );
};

export default TaskModal;
