import React, {useContext} from "react";
import {
    Box,
    Container, Divider,
    Drawer,
    IconButton,
    List,
    ListItem, ListItemButton, ListItemIcon,
    ListItemText,
    Toolbar,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import "./SidebarLayout.css";
import TodayIcon from "@mui/icons-material/Today";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import {Chip} from "@mui/joy";
import { SubscriptionContext } from "../context/subscriptionContext";
import moment from "moment-timezone";

const drawerWidth = 240;

const SidebarLayout = ({ children }) => {
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const { subscription } = useContext(SubscriptionContext);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const topMenuItems = [{
        text: "Today",
        icon: <TodayIcon />,
        link: "/dashboard",
    }, {
        text: "All Tasks",
        icon: <FormatAlignJustifyIcon />,
        link: "/dashboard?task=all",
    }];
    const bottomMenuItems = [{
        text: "Completed",
        icon: <TaskAltIcon />,
        link: "/dashboard?task=completed",
    }];
    const settings = [
        {
            name: "Profile",
            icon: <PersonOutlineIcon />,
            link: "/profile",
        },
        {
            name: "Subscription",
            icon: <WorkspacePremiumOutlinedIcon />,
            link: "/subscription",
        },
        {
            name: "Logout",
            icon: <LogoutOutlinedIcon />,
            link: "/logout",
        }];

    const calculateDaysLeft = (endDate) => {
        const today = moment().startOf("day"); // Consider only the date part, ignore the time
        const end = moment(endDate).startOf("day");
        const daysLeft = end.diff(today, "days"); // Calculate difference in days

        if (daysLeft > 1) {
            return `${daysLeft} days`;
        } else if (daysLeft === 1) {
            return "1 day";
        } else {
            return "expires today";
        }
    };

    const drawer = (
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", overflow: "auto" }}>
            <List>
                {topMenuItems.map((item) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton component="a" href={item.link}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {bottomMenuItems.map((item) => (
                    <ListItem key={item.text} disablePadding>
                        <ListItemButton component="a" href={item.link}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Box sx={{ flexGrow: 1 }} />
            <Divider />
            <List>
                {settings.map((item) => (
                    <ListItem key={item.name} disablePadding>
                        <ListItemButton component="a" href={item.link}>
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );


    return (
        <div className="app-body">
            <AppBar className="app-header" sx={{ ml: { sm: `${drawerWidth}px` }, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Container style={{ maxWidth: "100%"}}>
                    <Toolbar disableGutters>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { lg: "none" } }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box sx={{ flexGrow: "1"}}>
                            <h1 className="app-logo"><a className="app-logo" style={{
                                textDecoration: "none",
                                color: "white"
                            }} href="/dashboard?task=all">Task Patrol</a></h1>
                        </Box>
                        { (subscription?.payment_status === "trialing" || subscription?.payment_status === null) && (<Box>
                            <Chip slotProps={{ action: { component: "a", href: "/subscription" } }} color="danger" variant="soft" startDecorator={<WorkspacePremiumOutlinedIcon/>} sx={{
                                height: "32px",
                                padding: "0 10px",
                            }}>
                                Your trial expires in {calculateDaysLeft(subscription?.trial_end_date)}
                            </Chip>
                        </Box>)}
                    </Toolbar>
                </Container>
            </AppBar>
            <Box
                component="nav"
                sx={{ width: { sm: drawerWidth }, flexShrink: { md: 0 } }}
                aria-label="sidebar"
            >
                <Drawer
                    variant="temporary"
                    style={{zIndex: 1250}}
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        display: {xs: "block", lg: "none"},
                        ["& .MuiDrawer-paper"]: {width: drawerWidth, boxSizing: "border-box"},
                    }}
                >
                    <h1 className="app-logo sm-logo margin-t20" style={{ textAlign: "left", marginLeft: "20px" }}>Task Patrol</h1>
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: {xs: "none", lg: "block"},
                        ["& .MuiDrawer-paper"]: {
                            width: drawerWidth,
                            boxSizing: "border-box", bgcolor: "rgba(19, 203, 229, 0.1)" },
                    }}
                    open
                >
                    <Toolbar />
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    p: 3,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center", // Center the content vertically
                }}
            >
                <Box className="main-box">
                    {children}
                </Box>
            </Box>
        </div>
    );
};

export default SidebarLayout;