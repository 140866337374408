import SplitScreenLayout from "../../layouts/SplitScreenLayout";
import {Button} from "@mui/joy";
import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import useApi from "../../hooks/useApi";
import {Alert, Snackbar} from "@mui/material";

const Payment = () => {
    const {request} = useApi();

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const handleSubmit = async (plan) => {
        try {
            const response = await request({
                url: "/payment",
                method: "post",
                data: {plan},
            });

            if (response?.status === 302) {
                window.location.href = response.data.url;
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const isCanceled = searchParams.get("error") === "true";

        if (isCanceled) {
            setSnackbarMessage("Payment failed. Please try again.");
            setSnackbarSeverity("warning");
            setOpenSnackbar(true);
        }
    }, [location.search]);

    return (
        <SplitScreenLayout title="Select the package">
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }} >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} elevation={6} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <div className="top-button">
                <Link to={"/onboarding/communication"} className="margin-t20 link-navigation">Go back</Link>
            </div>
            <section id="pricing">
                <div className="pricing-table">
                    <div className="pricing-plan">
                        <span className="chip chip-green">7 day trial period</span>
                        <h1 className="pricing-title">Monthly</h1>
                        <h2 className="pricing-header">$19.99 / month</h2>
                        <ul className="pricing-features">
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Access to
                                All Features
                            </li>
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Unlimited
                                Task Creation
                            </li>
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Personalized
                                Accountability Options
                            </li>
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Real Human
                                Support
                            </li>
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>No
                                Commitment
                            </li>
                        </ul>
                        <span className="pricing-price">Monthly (pay every month $19.99)</span>
                        <Button type="button" size="lg" onClick={() => handleSubmit("monthly")}
                            className="form-button full-width margin-t20">Select</Button>
                    </div>
                    <div className="pricing-plan">
                        <span className="chip chip-green">7 day trial period</span><span className="chip chip-red">30% Discount</span>
                        <h1 className="pricing-title">Yearly</h1>
                        <h2 className="pricing-header">$13.99 / month</h2>
                        <ul className="pricing-features">
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Access to
                                All Features
                            </li>
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Unlimited
                                Task Creation
                            </li>
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Personalized
                                Accountability Options
                            </li>
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Real Human
                                Support
                            </li>
                            <li className="pricing-features-item"><span className="checkbox">&#10004;</span>Commit to
                                Success
                            </li>
                        </ul>
                        <span className="pricing-price">Yearly (pay every year once $167.88)</span>
                        <Button type="button" size="lg" onClick={() => handleSubmit("yearly")}
                            className="form-button full-width margin-t20">Select</Button>

                    </div>
                </div>
            </section>

        </SplitScreenLayout>
    );
};


export default Payment;