import React, {useEffect, useState} from "react";
import SidebarLayout from "./../../layouts/SidebarLayout";
import {Box, Typography, Input, Select, Option, Button} from "@mui/joy";
import moment from "moment-timezone";
import ReactFlagsSelect from "react-flags-select";
import {useFormik} from "formik";
import * as Yup from "yup";
import useApi from "../../hooks/useApi";
import { Snackbar, Alert } from "@mui/material";


const Profile = () => {
    const [country, setCountry] = useState("");
    const timezones = moment.tz.names();
    const { request } = useApi();
    const [user, setUser] = useState(null);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");


    const fetchUserData = async () => {
        try {
            const response = await request({
                url: "/user",
                method: "get"
            });

            if (response.status === 200) {
                setUser(response.data);
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        fetchUserData().catch((error) => {
            console.error("Error fetching user data:", error);
        });
    }, []);

    useEffect(() => {
        // Update form fields only when user data is available
        if (user) {
            formik.setFieldValue("firstName", user.first_name ?? "");
            formik.setFieldValue("lastName", user.last_name ?? "");
            formik.setFieldValue("email", user.email ?? "");
            formik.setFieldValue("timezone", user.timezone ?? "");
            formik.setFieldValue("country", user.country ?? "");
            setCountry(user.country ?? "");
        }
    }, [user]); // Dependency on user state


    const formik = useFormik({
        initialValues: {
            firstName: user?.first_name ?? "",
            lastName: user?.last_name ?? "",
            email: user?.email ?? "",
            country: user?.country ?? "",
            timezone: user?.timezone ?? "",
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            email: Yup.string().email("Invalid email address").required("Required"),
            country: Yup.string().required("Required"),
            timezone: Yup.string().required("Required"),
        }),
        onSubmit: async (values) => {
            try {
                const response = await request({
                    url: "/user",
                    method: "put",
                    data: values
                });

                if (response.status === 200) {
                    setSnackbarMessage("Profile updated successfully");
                    setSnackbarSeverity("success");
                    setOpenSnackbar(true);
                }
            } catch (error) {
                setSnackbarMessage("Error updating profile. Please try again.");
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            }
        },
    });

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    return (
        <SidebarLayout>
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} elevation={6} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Box sx={{ maxWidth: 600, margin: "70px auto 0 auto", minHeight: "calc(96vh - 70px)" }}>
                <form onSubmit={formik.handleSubmit}>
                    <Typography level="h4" sx={{mb: 3}}>
                        Profile
                    </Typography>
                    <small>First name</small>
                    <Input
                        placeholder="First Name"
                        name="firstName"
                        value={formik.values.firstName}
                        disabled
                        sx={{mb: 2}}
                        size="lg"
                    />
                    <small>Last name</small>
                    <Input
                        placeholder="Last Name"
                        name="lastName"
                        disabled
                        value={formik.values.lastName}
                        sx={{mb: 2}}
                        size="lg"
                    />
                    <small>Email</small>
                    <Input
                        placeholder="Email"
                        name="email"
                        disabled
                        value={formik.values.email}
                        sx={{mb: 2}}
                        size="lg"
                    />
                    <small>Country</small>
                    <ReactFlagsSelect
                        searchable
                        value={formik.values.country}
                        onSelect={(value) => {
                            formik.setFieldValue("country", value); // Directly set the value
                            setCountry(value); // Update local state if needed
                        }}
                        selected={country}
                        className="country-select margin-b20"
                    />
                    <small>Timezone</small>
                    <Select
                        name="timezone"
                        value={formik.values.timezone}
                        onChange={(event) => {
                            formik.setFieldValue("timezone", event?.target?.innerText);
                        }}
                        placeholder="Select timezone"
                        sx={{mb: 2}}
                        size="lg"
                    >
                        {timezones.map((tz) => (
                            <Option key={tz} value={tz}>{tz}</Option>
                        ))}
                    </Select>
                    <Button
                        className="form-button full-width margin-t20"
                        size="lg"
                        sx={{mb: 2, width: "100%"}}
                        type="submit"
                    >
                        Update the profile
                    </Button>
                </form>
            </Box>
        </SidebarLayout>
    );
};

export default Profile;
