
import React, {useCallback, useEffect, useState} from "react";
import SidebarLayout from "./../../layouts/SidebarLayout";
import TaskModal from "./../../components/TaskModal"; // Import the TaskModal component
import { Box, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TaskList from "./../../components/TaskList";
import useApi from "../../hooks/useApi";
import TaskDeleteModal from "../../components/TaskDeleteModal";
import {Button} from "@mui/joy";
import {useLocation} from "react-router-dom";
import TaskProofModal from "../../components/TaskProofModal";

const Dashboard = () => {
    const [isTaskModalOpen, setTaskModalOpen] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [editingTask, setEditingTask] = useState(null);
    const [proofTask, setProofTask] = useState(null);
    const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [taskToDelete, setTaskToDelete] = useState(null);
    const [taskModalKey, setTaskModalKey] = useState(0);
    const [taskProofModalOpen, setTaskProofModalOpen] = useState(false);

    const { request } = useApi();
    const location = useLocation();
    // const navigate = useNavigate();
    //
    // const fetchUserData = async () => {
    //     try {
    //         const response = await request({
    //             url: "/user",
    //             method: "get"
    //         });
    //
    //         if (response.status === 200) {
    //             if (response.data.payment_status !== "active" && response.data.payment_status !== "trialing") {
    //                 navigate("/subscription");
    //             }
    //         }
    //     } catch (error) {
    //         console.error("Error fetching user data:", error);
    //     }
    // };
    //
    // useEffect(() => {
    //     fetchUserData();
    // }, []);


    const fetchTasks = useCallback(async () => {
        try {
            // Extract query parameters from the URL
            const searchParams = new URLSearchParams(location.search);
            let queryParams = "";
            for (const [key, value] of searchParams) {
                queryParams += `${encodeURIComponent(key)}=${encodeURIComponent(value)}&`;
            }
            queryParams = queryParams.slice(0, -1); // Remove the trailing '&'

            // Construct the API URL with query parameters
            const apiUrl = `/task${queryParams ? `?${queryParams}` : ""}`;

            // Make the API request
            const response = await request({
                url: apiUrl,
                method: "get"
            });

            if (response.status === 200) {
                setTasks(response.data);
            }
        } catch (error) {
            console.error("Error fetching tasks:", error);
        }
    }, [location.search]);

    useEffect(() => {
        fetchTasks();
    }, [fetchTasks]);

    const handleAddTask = () => {
        setEditingTask(null);
        setTaskModalOpen(true);
        setTaskModalKey(prevKey => prevKey + 1);
    };

    const handleEditTask = (task) => {
        setEditingTask(task);
        setTaskModalOpen(true);
    };

    const handleProofSubmit = async (proofData) => {

        const formData = new FormData();
        proofData.files.forEach(file => {
            console.log(file);
            formData.append("attachments", file);
        });
        formData.append("description", proofData.description);

        const response = await request({
            url: `/task/${proofTask.id}/proof`,
            method: "post",
            data: formData
        });

        if (response.status === 201) {
            fetchTasks();
        }

        setTaskProofModalOpen(false);
    };

    const handleProofOpen = (task) => {
        setTaskProofModalOpen(true);
        setProofTask(task);
    };

    const handleTaskSubmit = async (taskData) => {

        const isEditing = editingTask !== null;
        const method = isEditing ? "put" : "post";
        const url = isEditing ? `/task/${editingTask.id}` : "/task";

        try {
            const response = await request({
                url: url,
                method: method,
                data: taskData,
            });
            if (response.status === 200 || response.status === 201) {
                fetchTasks(); // Refetch tasks
            }
        } catch (error) {
            console.error("Error submitting task:", error);
        }

        setTaskModalOpen(false);
    };

    const handleCloseModal = () => {
        setEditingTask(null); // Reset the editing task
        setTaskModalOpen(false);
    };

    const handleProofModalClose = () => {
        setProofTask(null);
        setTaskProofModalOpen(false);
    };

    const handleDeleteTask = (task) => {
        setTaskToDelete(task);
        setConfirmDeleteOpen(true);
    };

    const confirmDelete = async () => {
        if (taskToDelete) {
            // Perform the delete operation
            try {
                const response = await request({
                    url: `/task/${taskToDelete.id}`,
                    method: "delete"
                });

                if (response.status === 200) {
                    fetchTasks(); // Refetch tasks
                }
            } catch (error) {
                console.error("Error deleting task:", error);
            }
        }
        setConfirmDeleteOpen(false);
        setTaskToDelete(null);
    };

    const cancelDelete = () => {
        setConfirmDeleteOpen(false);
        setTaskToDelete(null);
    };

    return (
        <SidebarLayout>
            <Box className="app-main-content">
                { tasks.length > 0 && (
                    <>
                        <TaskList
                            tasks={tasks}
                            onAdd={handleAddTask}
                            onEdit={handleEditTask}
                            onDelete={handleDeleteTask}
                            onProofUpload={handleProofOpen}
                        />

                        <Fab
                            className="form-button fab-btn"
                            aria-label="add"
                            sx={{
                                position: "absolute",
                                bottom: 0,
                                right: 50,
                                color: "white",
                                borderRadius: "6px",
                                boxShadow: "none",
                                textTransform: "none",
                                fontSize: "18px",
                                fontFamily: "Roboto, sans-serif",
                            }}
                            onClick={handleAddTask}
                            variant="extended"
                        >
                            <AddIcon sx={{mr: 1}}/>
                Add Task
                        </Fab>
                    </>
                )}

                { tasks.length === 0 && (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <svg style={{margin: "0 auto", width: "30%"}} data-name="Layer 1"  width="797.5" height="434.5" viewBox="0 0 797.5 834.5">
                                <ellipse cx="308.5" cy="780" rx="308.5" ry="54.5" fill="#3f3d56"/>
                                <circle cx="496" cy="301.5" r="301.5" fill="#3f3d56"/>
                                <circle cx="496" cy="301.5" r="248.89787" opacity="0.05"/>
                                <circle cx="496" cy="301.5" r="203.99362" opacity="0.05"/>
                                <circle cx="496" cy="301.5" r="146.25957" opacity="0.05"/>
                                <path
                                    d="M398.42029,361.23224s-23.70394,66.72221-13.16886,90.42615,27.21564,46.52995,27.21564,46.52995S406.3216,365.62186,398.42029,361.23224Z"
                                    transform="translate(-201.25 -32.75)" fill="#d0cde1"/>
                                <path
                                    d="M398.42029,361.23224s-23.70394,66.72221-13.16886,90.42615,27.21564,46.52995,27.21564,46.52995S406.3216,365.62186,398.42029,361.23224Z"
                                    transform="translate(-201.25 -32.75)" opacity="0.1"/>
                                <path
                                    d="M415.10084,515.74682s-1.75585,16.68055-2.63377,17.55847.87792,2.63377,0,5.26754-1.75585,6.14547,0,7.02339-9.65716,78.13521-9.65716,78.13521-28.09356,36.8728-16.68055,94.81576l3.51169,58.82089s27.21564,1.75585,27.21564-7.90132c0,0-1.75585-11.413-1.75585-16.68055s4.38962-5.26754,1.75585-7.90131-2.63377-4.38962-2.63377-4.38962,4.38961-3.51169,3.51169-4.38962,7.90131-63.2105,7.90131-63.2105,9.65716-9.65716,9.65716-14.92471v-5.26754s4.38962-11.413,4.38962-12.29093,23.70394-54.43127,23.70394-54.43127l9.65716,38.62864,10.53509,55.3092s5.26754,50.04165,15.80262,69.356c0,0,18.4364,63.21051,18.4364,61.45466s30.72733-6.14547,29.84941-14.04678-18.4364-118.5197-18.4364-118.5197L533.62054,513.991Z"
                                    transform="translate(-201.25 -32.75)" fill="#2f2e41"/>
                                <path
                                    d="M391.3969,772.97846s-23.70394,46.53-7.90131,48.2858,21.94809,1.75585,28.97148-5.26754c3.83968-3.83968,11.61528-8.99134,17.87566-12.87285a23.117,23.117,0,0,0,10.96893-21.98175c-.463-4.29531-2.06792-7.83444-6.01858-8.16366-10.53508-.87792-22.826-10.53508-22.826-10.53508Z"
                                    transform="translate(-201.25 -32.75)" fill="#2f2e41"/>
                                <path
                                    d="M522.20753,807.21748s-23.70394,46.53-7.90131,48.28581,21.94809,1.75584,28.97148-5.26754c3.83968-3.83969,11.61528-8.99134,17.87566-12.87285a23.117,23.117,0,0,0,10.96893-21.98175c-.463-4.29531-2.06792-7.83444-6.01857-8.16367-10.53509-.87792-22.826-10.53508-22.826-10.53508Z"
                                    transform="translate(-201.25 -32.75)" fill="#2f2e41"/>
                                <circle cx="295.90488" cy="215.43252" r="36.90462" fill="#ffb8b8"/>
                                <path
                                    d="M473.43048,260.30832S447.07,308.81154,444.9612,308.81154,492.41,324.62781,492.41,324.62781s13.70743-46.39439,15.81626-50.61206Z"
                                    transform="translate(-201.25 -32.75)" fill="#ffb8b8"/>
                                <path
                                    d="M513.86726,313.3854s-52.67543-28.97148-57.943-28.09356-61.45466,50.04166-60.57673,70.2339,7.90131,53.55335,7.90131,53.55335,2.63377,93.05991,7.90131,93.93783-.87792,16.68055.87793,16.68055,122.90931,0,123.78724-2.63377S513.86726,313.3854,513.86726,313.3854Z"
                                    transform="translate(-201.25 -32.75)" fill="#d0cde1"/>
                                <path
                                    d="M543.2777,521.89228s16.68055,50.91958,2.63377,49.16373-20.19224-43.89619-20.19224-43.89619Z"
                                    transform="translate(-201.25 -32.75)" fill="#ffb8b8"/>
                                <path
                                    d="M498.50359,310.31267s-32.48318,7.02339-27.21563,50.91957,14.9247,87.79237,14.9247,87.79237l32.48318,71.11182,3.51169,13.16886,23.70394-6.14547L528.353,425.32067s-6.14547-108.86253-14.04678-112.37423A33.99966,33.99966,0,0,0,498.50359,310.31267Z"
                                    transform="translate(-201.25 -32.75)" fill="#d0cde1"/>
                                <polygon points="277.5 414.958 317.885 486.947 283.86 411.09 277.5 414.958"
                                    opacity="0.1"/>
                                <path
                                    d="M533.896,237.31585l.122-2.82012,5.6101,1.39632a6.26971,6.26971,0,0,0-2.5138-4.61513l5.97581-.33413a64.47667,64.47667,0,0,0-43.1245-26.65136c-12.92583-1.87346-27.31837.83756-36.182,10.43045-4.29926,4.653-7.00067,10.57018-8.92232,16.60685-3.53926,11.11821-4.26038,24.3719,3.11964,33.40938,7.5006,9.18513,20.602,10.98439,32.40592,12.12114,4.15328.4,8.50581.77216,12.35457-.83928a29.721,29.721,0,0,0-1.6539-13.03688,8.68665,8.68665,0,0,1-.87879-4.15246c.5247-3.51164,5.20884-4.39635,8.72762-3.9219s7.74984,1.20031,10.062-1.49432c1.59261-1.85609,1.49867-4.559,1.70967-6.99575C521.28248,239.785,533.83587,238.70653,533.896,237.31585Z"
                                    transform="translate(-201.25 -32.75)" fill="#2f2e41"/>
                                <circle cx="559" cy="744.5" r="43" fill="#e512a7"/>
                                <circle cx="54" cy="729.5" r="43" fill="#e512a7"/>
                                <circle cx="54" cy="672.5" r="31" fill="#e512a7"/>
                                <circle cx="54" cy="624.5" r="22" fill="#e512a7"/>
                            </svg>
                        </div>
                        <div style={{display: "flex", justifyContent: "center", width: "100%"}}>
                            <Button className="form-button margin-t20" onClick={handleAddTask}
                                startDecorator={<AddIcon/>}>Add a task</Button>
                        </div>
                    </>
                )}

                <TaskModal
                    key={taskModalKey} // Use the key here
                    open={isTaskModalOpen}
                    handleClose={handleCloseModal}
                    handleSubmit={handleTaskSubmit}
                    task={editingTask}
                />

                <TaskDeleteModal
                    open={isConfirmDeleteOpen}
                    handleClose={cancelDelete}
                    handleConfirm={confirmDelete}
                />

                <TaskProofModal
                    open={taskProofModalOpen}
                    handleClose={handleProofModalClose}
                    handleSubmit={handleProofSubmit}
                    task={proofTask}
                />
            </Box>
        </SidebarLayout>
    );
};

export default Dashboard;
