import React from "react";
import { Input } from "@mui/joy";

const InputField = ({ name, type, placeholder, onChange, label, value, error, className, size }) => (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <span style={{ fontSize: "18px" }}>{label}</span>
        <Input
            name={name}
            type={type}
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            className={className}
            size={size ?? "lg"}
        />
        {error ? <div>{error}</div> : null}
    </div>
);

export default InputField;