import React from "react";
import {Box, IconButton, Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import EventIcon from "@mui/icons-material/Event"; // For due date
import RepeatIcon from "@mui/icons-material/Repeat"; // For recurring tasks
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import DoneOutlineOutlinedIcon from "@mui/icons-material/DoneOutlineOutlined";
import moment from "moment-timezone";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import {Alert, Button, Modal, ModalClose, Sheet} from "@mui/joy";
import Grid from "@mui/joy/Grid";

const Task = ({ task, onEdit, onDelete, onProofUpload }) => {

    const [open, setOpen] = React.useState(false);

    const priorityIcons = {
        high: {
            icon: <ArrowUpwardIcon sx={{ fontSize: "1rem", color: "red" }} />,
            label: "High",
        },
        normal: {
            icon: <ArrowRightAltIcon sx={{ fontSize: "1rem", color: "green" }} />,
            label: "Normal",
        },
        low: {
            icon: <ArrowDownwardIcon sx={{ fontSize: "1rem", color: "blue" }} />,
            label: "Low",
        },
    };

    const priority = task.importance_score ? priorityIcons[task.importance_score] : null;
    let dueDate = null;
    if (task.due_date) {
        dueDate = moment.utc(task.due_date).local().format("Do MMM YYYY, h:mm A");
    }

    return (
        <Box sx={{ display: "flex", alignItems: "center", paddingY: "8px" }} class="task-item">
            <Grid container>
                <Grid sm={11} xs={9} className={ task.status === "done" ? "task-main task-done" : "task-main" }>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="body1">
                            <h3 className="task-title">{task.title} {task.description && <TextSnippetOutlinedIcon onClick={() => setOpen(true)} sx={{ fontSize: "1rem", marginLeft: "5px" }} />}</h3>
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            {dueDate && (
                                <Box sx={{ display: "flex", alignItems: "center", marginRight: "15px" }}>
                                    <EventIcon sx={{ fontSize: "1rem" }} />
                                    <Typography variant="caption" color="text.secondary" sx={{ marginLeft: "5px" }}>
                                        {dueDate}
                                    </Typography>
                                </Box>
                            )}
                            {priority && (
                                <Box sx={{ display: "flex", alignItems: "center", marginRight: "15px" }}>
                                    {priority.icon}
                                    <Typography variant="caption" sx={{ marginLeft: "5px" }}>
                                        {priority.label}
                                    </Typography>
                                </Box>
                            )}
                            {task.is_recurring && task.recurring_frequency && (
                                <Box sx={{ display: "flex", alignItems: "center", marginRight: "15px" }}>
                                    <RepeatIcon sx={{ fontSize: "1rem", color: "success.main" }} />
                                    <Typography variant="caption" sx={{ marginLeft: "5px" }}>
                                        ({task.recurring_frequency.toUpperCase()})
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                </Grid>
                <Grid sm={1} xs={3}>
                    <Box className="task-icons margin-t20" display={{ display: "flex" }}>
                        {/*<IconButton onClick={() => onProofUpload(task)}><CloudUploadIcon color="disabled" /></IconButton>*/}
                        <IconButton onClick={() => onEdit(task)}><EditIcon color="disabled" /></IconButton>
                        <IconButton onClick={() => onDelete(task)}><DeleteIcon color="disabled" /></IconButton>
                    </Box>
                </Grid>
                { task.status === "not_done" && <Grid item md={3} mdOffset={9} sm={12} xs={12} className="task-footer">
                    <Button variant="outline" size="sm" startDecorator={<DoneOutlineOutlinedIcon fontSize="small" />} className="primary-btn-outline full-width" onClick={() => onProofUpload(task)}>Submit Proof</Button>
                </Grid>
                }
                { task.status === "in_review" && <Grid item xs={12} className="margin-t20">
                    <Alert variant="outlined" color="warning">Proof submitted. Waiting for approval.</Alert>
                </Grid>
                }
                { task.status === "done" && <Grid item xs={12} className="margin-t20">
                    <Alert variant="outlined" color="success">This ticket is DONE! Congrats.</Alert>
                </Grid>
                }
            </Grid>
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-desc"
                open={open}
                onClose={() => setOpen(false)}
                sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 800,
                        minWidth: 500,
                        borderRadius: "md",
                        p: 3,
                        boxShadow: "lg",
                        outlineColor: "#ffffff !important",
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} />
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="h4"
                        textColor="inherit"
                        fontWeight="lg"
                        mb={1}
                    >
                        {task.title}
                    </Typography>
                    <Typography id="modal-desc" textColor="text.tertiary">
                        <div dangerouslySetInnerHTML={{ __html: task.description }} />
                    </Typography>
                </Sheet>
            </Modal>
        </Box>
    );
};

export default Task;
