import React, {useEffect, useState} from "react";
import { Navigate } from "react-router-dom";
import useApi from "./../../hooks/useApi";

const withAuth = (WrappedComponent) => {
    const WithAuth = (props) => {
        const [isAuthenticated, setIsAuthenticated] = useState(null);
        const { request } = useApi();

        useEffect(() => {
            const validateSession = async () => {
                const response = await request({
                    url: "/validate-session", // Adjust the endpoint as needed
                    method: "get"
                });

                if (response?.status === 200) {
                    setIsAuthenticated(true);
                } else {
                    setIsAuthenticated(false);
                }
            };

            validateSession().catch(() => {
                setIsAuthenticated(false);
            });
        }, []);

        if (isAuthenticated === null) {
            return <div></div>;
        }

        if (!isAuthenticated) {
            return <Navigate to="/" />;
        }

        return <WrappedComponent {...props} />;
    };

    WithAuth.displayName = `WithAuth(${getDisplayName(WrappedComponent)})`;
    return WithAuth;
};

function getDisplayName(WrappedComponent) {
    return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default withAuth;
