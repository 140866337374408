import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { useLoading } from "../context/loadingContext";

const GlobalLoading = () => {
    const { loading } = useLoading();

    if (!loading) return null;

    // Define the inline styles
    const overlayStyle = {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(255, 255, 255, 1)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1500
    };

    return (
        <div style={overlayStyle}>
            <CircularProgress />
        </div>
    );
};

export default GlobalLoading;