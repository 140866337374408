import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ValidateEmail from "./pages/auth/ValidateEmail";
import LoadingProvider from "./context/loadingContext";
import { SubscriptionProvider } from "./context/subscriptionContext";
import AllPosts from "./pages/blog/AllPosts";
import SinglePost from "./pages/blog/SinglePost";


import {
    ProtectedDashboard,
    ProtectedProfile,
    ProtectedSubscription,
    ProtectedPersonalInfo,
    ProtectedAccountabilitySettings,
    ProtectedCommunicationSettings,
    ProtectedPayment,
    ProtectedResetPassword,
    ProtectedWelcome, ProtectedCompleted,
    ProtectedLogout, ProtectedConsequencesSettings
} from "./pages/protectedPages";
import "./App.css";
import GlobalLoading from "./components/GlobalLoading";

function App() {
    return (
        <LoadingProvider>
            <SubscriptionProvider>
                <Router>
                    <GlobalLoading />
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/logout" element={<ProtectedLogout />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/reset-password" element={<ProtectedResetPassword />} />
                        <Route path="/verify-email" element={<ValidateEmail />} />
                        <Route path="/onboarding/welcome" element={<ProtectedWelcome />} />
                        <Route path="/onboarding/personal-data" element={<ProtectedPersonalInfo />} />
                        <Route path="/onboarding/accountability" element={<ProtectedAccountabilitySettings />} />
                        <Route path="/onboarding/communication" element={<ProtectedCommunicationSettings />} />
                        <Route path="/onboarding/consequences" element={<ProtectedConsequencesSettings />} />
                        <Route path="/onboarding/payment" element={<ProtectedPayment />} />
                        <Route path="/onboarding/completed" element={<ProtectedCompleted />} />
                        <Route path="/dashboard" element={<ProtectedDashboard />} />
                        <Route path="/profile" element={<ProtectedProfile />} />
                        <Route path="/subscription" element={<ProtectedSubscription />} />
                        <Route path="/blog" element={<AllPosts />} />
                        <Route path="/blog/:slug" element={<SinglePost />} />
                    </Routes>
                </Router>
            </SubscriptionProvider>
        </LoadingProvider>
    );
}

export default App;