import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {Button} from "@mui/joy";
import { Radio, FormControlLabel, RadioGroup } from "@mui/material";
import SplitScreenLayout from "../../layouts/SplitScreenLayout";
import {useNavigate} from "react-router-dom";
import useApi from "../../hooks/useApi";

const AccountabilitySettings = () => {
    const navigate = useNavigate();
    const { request } = useApi();

    const formik = useFormik({
        initialValues: {
            strictnessLevel: "",
        },
        validationSchema: Yup.object({
            strictnessLevel: Yup.string().required("Required"),
        }),
        onSubmit: async (values) => {
            try {
                const response = await request({
                    url: "/accountability",
                    method: "post",
                    data: values,
                });

                if (response?.status === 200) {
                    const response = await request({
                        url: "/update-status",
                        method: "post",
                        data: {
                            onboardingStatus: "communication",
                        },
                    });

                    if (response?.status === 200) {
                        navigate("/onboarding/communication");
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
    });

    const handleRadioChange = (event) => {
        formik.setFieldValue(event.target.name, event.target.value);
    };

    return (
        <SplitScreenLayout title="How strict do you want us to be with you?">
            <form onSubmit={formik.handleSubmit} className="form-container">
                <RadioGroup
                    name="strictnessLevel"
                    value={formik.values.strictness}
                    onChange={handleRadioChange}
                    className="margin-b20"
                >
                    <FormControlLabel
                        value="loose"
                        control={<Radio/>}
                        className="radio-button radio-label"
                        label={
                            <span>
                                <strong>Loose</strong> - I just need reminders from time to time. I am accountable enough to do my tasks.
                            </span>
                        }
                    />
                    <FormControlLabel
                        value="normal"
                        control={<Radio/>}
                        className="radio-button radio-label"
                        label={
                            <span>
                                <strong>Normal</strong> - I need to be reminded when I am slacking, and give proof when I am done with a task.
                            </span>
                        }
                    />
                    <FormControlLabel
                        value="strict"
                        control={<Radio/>}
                        className="radio-button radio-label"
                        label={
                            <span>
                                <strong>Strict</strong> - If I am not reminded and I am not asked for proof, I will not do my tasks.
                            </span>
                        }
                    />
                </RadioGroup>

                <Button type="submit" size="lg" className="form-button full-width">Next</Button>
            </form>
        </SplitScreenLayout>
    );
};

export default AccountabilitySettings;
