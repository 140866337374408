import React from "react";
import "./BlogLayout.css";

const BlogLayout = ({ children }) => {
    return (
        <div className="blog-container">
            {children}
        </div>
    );
};

export default BlogLayout;