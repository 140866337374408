import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/joy";
import { useFormik } from "formik";
import * as Yup from "yup";
import SplitScreenLayout from "../../layouts/SplitScreenLayout";
import InputField from "../../components/InputField";
import useApi from "../../hooks/useApi";
const ResetPassword = () => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            newPassword: "",
            confirmNewPassword: "",
        },
        validationSchema: Yup.object({
            newPassword: Yup.string().required("Required"),
            confirmNewPassword: Yup.string()
                .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
                .required("Required"),
        }),
        onSubmit: (values) => {
            console.log(values);
            // Logic to handle reset password
        },
    });

    const { request } = useApi();

    useEffect(() => {
        const checkAuth = async () => {
            const response = await request({
                url: "/validate-session", // Adjust the endpoint as needed
                method: "get"
            });

            if (response?.status === 200) {
                navigate("/dashboard?task=all");
            }
        };

        checkAuth().catch(() => {
            // Do nothing
        });
    }, []);

    return (
        <SplitScreenLayout title="Reset Password">
            <form onSubmit={formik.handleSubmit} className="form-container contained-form">
                <InputField
                    name="newPassword"
                    type="password"
                    label="New Password"
                    placeholder="Enter your new password"
                    onChange={formik.handleChange}
                    value={formik.values.newPassword}
                    error={formik.touched.newPassword && formik.errors.newPassword ? formik.errors.newPassword : null}
                />
                <InputField
                    name="confirmNewPassword"
                    type="password"
                    label="Confirm Password"
                    placeholder="Confirm your new password"
                    onChange={formik.handleChange}
                    value={formik.values.confirmNewPassword}
                    error={formik.touched.confirmNewPassword && formik.errors.confirmNewPassword ? formik.errors.confirmNewPassword : null}
                />
                {/*<Button type="submit" size="lg" className="form-button">Reset Password</Button>*/}
            </form>
            <div className="center-text margin-t20 contained-form">
                <Button
                    size="lg"
                    className="form-button"
                    onClick={() => navigate("/")}
                >
                    Back to Login
                </Button>
            </div>
        </SplitScreenLayout>
    );
};

export default ResetPassword;
