import BlogLayout from "../../layouts/BlogLayout";
import Grid from "@mui/joy/Grid";
import React from "react";
import {Chip, Typography, Card, Button} from "@mui/joy";


const AllPosts = () => {
    return (
        <BlogLayout>
            <Grid container spacing={2} columns={12} sx={{ flexGrow: 1 }} alignItems="stretch" direction="row">
                <Grid md={6} sm={12} lg={4} item>
                    <Card variant="outlined" className="blog-article-card">
                        <Typography level="h1" className="blog-article-title">Title</Typography>
                        <img className="blog-article-image" src="https://via.placeholder.com/350x200" alt="placeholder" />
                        <Chip>Productivity</Chip>
                        <Typography level="p" className="blog-article-excerpt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sapien eros, dictum nec imperdiet eu, bibendum sed enim. Aliquam sed...</Typography>
                        <Button href="/blog/slug" variant="contained">Read More</Button>
                    </Card>
                </Grid>
                <Grid md={6} sm={12} lg={4} item>
                    <Card variant="outlined" className="blog-article-card">
                        <Typography level="h1" className="blog-article-title">Title</Typography>
                        <img className="blog-article-image" src="https://via.placeholder.com/350x200" alt="placeholder" />
                        <Chip>Productivity</Chip>
                        <Typography level="p" className="blog-article-excerpt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sapien eros, dictum nec imperdiet eu, bibendum sed enim. Aliquam sed Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sapien eros, dictum nec imperdiet eu, bibendum sed enim. Aliquam sed...</Typography>
                        <Button href="/blog/slug" variant="contained">Read More</Button>
                    </Card>
                </Grid>
                <Grid md={6} sm={12} lg={4} item>
                    <Card variant="outlined" className="blog-article-card">
                        <Typography level="h1" className="blog-article-title">Title</Typography>
                        <img className="blog-article-image" src="https://via.placeholder.com/350x200" alt="placeholder" />
                        <Chip>Productivity</Chip>
                        <Typography level="p" className="blog-article-excerpt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sapien eros, dictum nec imperdiet eu, bibendum sed enim. Aliquam sed...</Typography>
                        <Button href="/blog/slug" variant="contained">Read More</Button>
                    </Card>
                </Grid>
                <Grid md={6} sm={12} lg={4} item>
                    <Card variant="outlined" className="blog-article-card">
                        <Typography level="h1" className="blog-article-title">Title</Typography>
                        <img className="blog-article-image" src="https://via.placeholder.com/350x200" alt="placeholder" />
                        <Chip>Productivity</Chip>
                        <Typography level="p" className="blog-article-excerpt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sapien eros, dictum nec imperdiet eu, bibendum sed enim. Aliquam sed Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sapien eros, dictum nec imperdiet eu, bibendum sed enim. Aliquam sed...</Typography>
                        <Button href="/blog/slug" variant="contained">Read More</Button>
                    </Card>
                </Grid>
                <Grid md={6} sm={12} lg={4} item>
                    <Card variant="outlined" className="blog-article-card">
                        <Typography level="h1" className="blog-article-title">Title</Typography>
                        <img className="blog-article-image" src="https://via.placeholder.com/350x200" alt="placeholder" />
                        <Chip>Productivity</Chip>
                        <Typography level="p" className="blog-article-excerpt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sapien eros, dictum nec imperdiet eu, bibendum sed enim. Aliquam sed...</Typography>
                        <Button href="/blog/slug" variant="contained">Read More</Button>
                    </Card>
                </Grid>
                <Grid md={6} sm={12} lg={4} item>
                    <Card variant="outlined" className="blog-article-card">
                        <Typography level="h1" className="blog-article-title">Title</Typography>
                        <img className="blog-article-image" src="https://via.placeholder.com/350x200" alt="placeholder" />
                        <Chip>Productivity</Chip>
                        <Typography level="p" className="blog-article-excerpt">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sapien eros, dictum nec imperdiet eu, bibendum sed enim. Aliquam sed Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam sapien eros, dictum nec imperdiet eu, bibendum sed enim. Aliquam sed...</Typography>
                        <Button href="/blog/slug" variant="contained">Read More</Button>
                    </Card>
                </Grid>
            </Grid>
        </BlogLayout>
    );
};


// generate lorem ipsum text
// https://www.lipsum.com/


export default AllPosts;