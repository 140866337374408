import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useApi from "../../hooks/useApi";

const Logout = () => {
    const navigate = useNavigate();
    const {request} = useApi();

    useEffect(() => {
        const logout = async () => {
            const response = await request({
                url: "/logout", // Adjust the endpoint as needed
                method: "post"
            });

            if (response?.status === 200) {
                navigate("/");
            } else if (response?.status === 401 || response?.status === 403) {
                navigate("/");
            }
        };

        logout().catch(() => {
            navigate("/");
        });
    }, []);

    // Optionally, you can render a loading message or spinner
    return (
        <div>Logging out...</div>
    );
};

export default Logout;