import React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";
import ReactFlagsSelect from "react-flags-select";
import SplitScreenLayout from "../../layouts/SplitScreenLayout";
import InputField from "../../components/InputField";
import {Button, Option, Select} from "@mui/joy";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import useApi from "../../hooks/useApi";
import {DatePicker} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
const PersonalInfo = () => {
    const navigate = useNavigate();
    const timezones = moment.tz.names();
    const { request } = useApi();

    const [country, setCountry] = React.useState("US");


    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            country: country,
            timezone: "UTC",
            dob: ""
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required("Required"),
            lastName: Yup.string().required("Required"),
            country: Yup.string().required("Required"),
            timezone: Yup.string().required("Required"),
            dob: Yup.string().required("Required"),
        }),
        onSubmit: async (values) => {
            try {
                const response = await request({
                    url: "/personal-data",
                    method: "post",
                    data: values,
                });

                if (response?.status === 200) {
                    const response = await request({
                        url: "/update-status",
                        method: "post",
                        data: {
                            onboardingStatus: "accountability",
                        },
                    });

                    if (response?.status === 200) {
                        navigate("/onboarding/accountability");
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
    });

    return (
        <SplitScreenLayout title="Personal Information">
            <form onSubmit={formik.handleSubmit} className="form-container">
                <InputField
                    name="firstName"
                    type="text"
                    placeholder="First Name"
                    onChange={formik.handleChange}
                    value={formik.values.firstName}
                    error={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : null}
                />
                <InputField
                    name="lastName"
                    type="text"
                    placeholder="Last Name"
                    onChange={formik.handleChange}
                    value={formik.values.lastName}
                    error={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : null}
                />

                <span style={{fontSize: "18px", marginBottom: "-10px"}}>Country</span>
                <ReactFlagsSelect
                    searchable
                    value={formik.values.country}
                    onSelect={(value) => {
                        formik.setFieldValue("country", value); // Directly set the value
                        setCountry(value); // Update local state if needed
                    }}
                    selected={country}
                    onChange={value => formik.setFieldValue("country", value.value)}
                    className="country-select"
                />
                <span style={{fontSize: "18px", marginBottom: "-10px"}}>Your timezone</span>
                <Select
                    name="timezone"
                    value={formik.values.timezone}
                    onChange={(event) => {
                        formik.setFieldValue("timezone", event.target.innerText);
                    }}
                    placeholder="Select timezone"
                    size="lg"
                >
                    {timezones.map((tz) => (
                        <Option key={tz} value={tz}>{tz}</Option>
                    ))}
                </Select>
                <span style={{fontSize: "18px", marginBottom: "-10px"}}>Date of birth <small>(month/day/year)</small></span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        name="dob"
                        onChange={(value) => formik.setFieldValue("dob", value)}
                        sx={{mb: 2}}
                        size="lg"
                        className="datepicker"
                    />
                </LocalizationProvider>

                <Button size="lg" className="form-button" type="submit">
                    Next
                </Button>
            </form>
        </SplitScreenLayout>
    );
};

export default PersonalInfo;