import React from "react";
import { useFormik } from "formik";
import {Button} from "@mui/joy";
import SplitScreenLayout from "../../layouts/SplitScreenLayout";
import {useNavigate} from "react-router-dom";
import useApi from "../../hooks/useApi";

const Welcome = () => {
    const navigate = useNavigate();
    const { request } = useApi();

    const formik = useFormik({
        initialValues: {},
        onSubmit: async () => {
            try {
                const response = await request({
                    url: "/update-status",
                    method: "post",
                    data: {
                        onboardingStatus: "personal-data",
                    },
                });

                if (response?.status === 200) {
                    navigate("/onboarding/personal-data");
                }

            } catch (error) {
                console.log(error);
            }
        },
    });

    return (
        <SplitScreenLayout title="Welcome">
            <h3 className="onboardingText">Ready to unlock your full potential? Let us begin with a few
                important questions. <br/><br/>Your answers will guide us in creating a personalized plan for you, one that keeps
                you motivated and focused on achieving your real-world goals. <br/><br/>Together, we will embark on this
                exciting journey towards success and self-improvement.</h3>
            <form onSubmit={formik.handleSubmit} className="contained-form">
                <Button type="submit" size="lg" className="form-button full-width margin-t20">Let&apos;s start</Button>
            </form>
        </SplitScreenLayout>
    );
};

export default Welcome;
