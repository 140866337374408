import React, {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/joy";
import { useFormik } from "formik";
import * as Yup from "yup";
import SplitScreenLayout from "../../layouts/SplitScreenLayout";
import InputField from "../../components/InputField";
import useApi from "../../hooks/useApi";

const ForgotPassword = () => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email address").required("Required"),
        }),
        onSubmit: (values) => {
            console.log(values);
            // Logic to handle forgot password
        },
    });

    const { request } = useApi();

    useEffect(() => {
        const checkAuth = async () => {
            const response = await request({
                url: "/validate-session", // Adjust the endpoint as needed
                method: "get"
            });

            if (response?.status === 200) {
                navigate("/dashboard?task=all");
            }
        };

        checkAuth().catch(() => {
            // Do nothing
        });
    }, []);

    return (
        <SplitScreenLayout title="Forgot Password">
            <form onSubmit={formik.handleSubmit} className="form-container contained-form">
                <InputField
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    label="Email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                />
                <Button type="submit" size="lg" className="form-button">Send Reset Link</Button>
            </form>
            <div className="center-text margin-t20  contained-form">
                <Button
                    size="lg"
                    className="form-button"
                    onClick={() => navigate("/")}
                >
                    Back to Login
                </Button>
            </div>
        </SplitScreenLayout>
    );
};

export default ForgotPassword;
