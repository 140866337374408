import React from "react";
import "./SplitScreenLayout.css";
import logo from "./../assets/images/hero.png";

const SplitScreenLayout = ({ children, title }) => {
    return (
        <div style={{display: "flex", height: "100vh"}}>

            {/* Children content: login or register form */}
            <div className="left-screen">
                <div className="content-container">
                    <h1 className="logo sm-logo">Task Patrol</h1>
                    <h2 style={{textAlign: "center", lineHeight: "1.7"}}>{title}</h2>
                    {children}
                </div>
            </div>

            <div className="right-screen">
                <h1 className="logo">Task Patrol</h1>
                <div style={{textAlign: "center"}}>
                    <img src={logo} alt="Task Patrol" className="banner-front-img"/>
                </div>
            </div>


        </div>
    );
};

export default SplitScreenLayout;