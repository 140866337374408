import React from "react";
import { useFormik } from "formik";
import {Button} from "@mui/joy";
import SplitScreenLayout from "../../layouts/SplitScreenLayout";
import {useNavigate} from "react-router-dom";

const Completed = () => {
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {},
        onSubmit: () => {
            navigate("/dashboard?task=all");
        },
    });

    return (
        <SplitScreenLayout title="You are ready to start!">
            <h3 className="onboardingText">Congratulations on completing your onboarding! You are now all set to take the next big step. <br/><br/>
                Let us dive into creating your first task. Remember, each task you set is a building block towards your goals. So, go ahead, define your first milestone and let us start this journey of achievement together.
                <br/><br/>You are not just planning tasks; you are shaping your future, one step at a time!</h3>
            <form onSubmit={formik.handleSubmit} className="contained-form">
                <Button type="submit" size="lg" className="form-button full-width margin-t20">Create your first task</Button>
            </form>
        </SplitScreenLayout>
    );
};

export default Completed;
