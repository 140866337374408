import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {Alert, Button, Option, Select} from "@mui/joy";
import { Radio, FormControlLabel, RadioGroup } from "@mui/material";
import SplitScreenLayout from "../../layouts/SplitScreenLayout";
import InputField from "../../components/InputField";
import {Link, useNavigate} from "react-router-dom";
import useApi from "../../hooks/useApi";

const ConsequencesSettings = () => {
    const navigate = useNavigate();
    const { request } = useApi();

    const formik = useFormik({
        initialValues: {
            consequence: "",
            charityName: "",
            charityAmount: "",
            accountabilityName: "",
            accountabilityRelationship: "",
            accountabilityMedium: "",
            accountabilityMediumInfo: "",
        },
        validationSchema: Yup.object({
            consequence: Yup.string().required("Required"),
            charityName: Yup.string().when("consequence", {
                is: "charity",
                then: () => Yup.string().required("Required"),
                otherwise: () => Yup.string().notRequired(),
            }),
            charityAmount: Yup.string().when("consequence", {
                is: "charity",
                then: () => Yup.string().required("Required"),
                otherwise: () => Yup.string().notRequired(),
            }),
            accountabilityName: Yup.string().when("consequence", {
                is: "accountability-buddy",
                then: () => Yup.string().required("Required"),
                otherwise: () => Yup.string().notRequired(),
            }),
            accountabilityRelationship: Yup.string().when("consequence", {
                is: "accountability-buddy",
                then: () => Yup.string().required("Required"),
                otherwise: () => Yup.string().notRequired(),
            }),
            accountabilityMedium: Yup.string().when("consequence", {
                is: "accountability-buddy",
                then: () => Yup.string().required("Required"),
                otherwise: () => Yup.string().notRequired(),
            }),
            accountabilityMediumInfo: Yup.string().when("consequence", {
                is: "accountability-buddy",
                then: () => Yup.string().required("Required"),
                otherwise: () => Yup.string().notRequired(),
            }),
        }),
        onSubmit: async (values) => {
            try {
                const response = await request({
                    url: "/consequences",
                    method: "post",
                    data: {
                        consequence: values.consequence,
                        charityName: values.charityName,
                        charityAmount: values.charityAmount,
                        accountabilityName: values.accountabilityName,
                        accountabilityRelationship: values.accountabilityRelationship,
                        accountabilityMedium: values.accountabilityMedium,
                        accountabilityMediumInfo: values.accountabilityMediumInfo,
                    },
                });

                const onboardingStatus = values.consequence === "charity" ? "payment" : "completed";
                const navigateTo = values.consequence === "charity" ? "/onboarding/payment" : "/onboarding/completed";

                if (response?.status === 200) {
                    const response = await request({
                        url: "/update-status",
                        method: "post",
                        data: {
                            onboardingStatus: onboardingStatus,
                        },
                    });

                    if (response?.status === 200) {
                        navigate(navigateTo);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        },
    });

    const handleRadioChange = (event) => {
        formik.setFieldValue(event.target.name, event.target.value);
    };

    const charities = [
        "Against Malaria Foundation",
        "Doctors Without Borders",
        "Dolly Parton's Imagination Library",
        "Electronic Frontier Foundation",
        "GiveDirectly",
        "GiveWell",
        "Greenpeace",
        "Helen Keller International",
        "Heterodox Academy",
        "Khan Academy",
        "Make-A-Wish Foundation",
        "Matriculate",
        "Random Acts of Kindness",
        "Schistosomiasis Control Initiative",
        "Sightsavers",
        "The Nature Conservancy",
        "The Ocean Cleanup",
        "Water.org",
        "Wikipedia",
        "World Wildlife Fund"
    ];


    return (
        <SplitScreenLayout title="What are the consequences you would like to face if you fail a task?">
            <div className="top-button">
                <Link to={"/onboarding/accountability"} className="margin-t20 link-navigation">Go back</Link>
            </div>
            <form onSubmit={formik.handleSubmit} className="form-container">
                <RadioGroup
                    name="consequence"
                    value={formik.values.consequence}
                    onChange={handleRadioChange}
                >
                    <FormControlLabel value="charity" control={<Radio/>} className="radio-button radio-label"
                        label="Give a donation to the charity of your choice"/>
                    {formik.values.consequence === "charity" && (
                        <div className="sub-option">
                            <Alert variant="soft" color="danger" className="margin-b20">If you choose a charity as a consequence, you need to add the payment information on the next page!</Alert>
                            <small>Charity</small>
                            <Select
                                style={{ marginTop: "10px"}}
                                name="charityName"
                                value={formik.values.timezone}
                                onChange={(event) => {
                                    formik.setFieldValue("charityName", event.target.innerText);
                                }}
                                placeholder="Select charity"
                                size="lg"
                                className="margin-b20"
                            >
                                {charities.map((charity) => (
                                    <Option key={charity} value={charity}>{charity}</Option>
                                ))}
                            </Select>
                            <small>Amount</small>
                            <InputField
                                name="charityAmount"
                                type="number"
                                onChange={formik.handleChange}
                                value={formik.values.charityAmount}
                                error={formik.touched.charityAmount && formik.errors.charityAmount ? formik.errors.charityAmount : null}
                            />
                        </div>

                    )}
                    <FormControlLabel value="accountability-buddy" control={<Radio/>} className="radio-button radio-label"
                        label="Accountability buddy"/>
                    {formik.values.consequence === "accountability-buddy" && (
                        <div className="sub-option">
                            <small>Name of the person</small>
                            <InputField
                                name="accountabilityName"
                                type="text"
                                className="margin-b20"
                                onChange={formik.handleChange}
                                value={formik.values.accountabilityName}
                                error={formik.touched.accountabilityName && formik.errors.accountabilityName ? formik.errors.accountabilityName : null}
                            />
                            <small>Your relationship with the person</small>
                            <InputField
                                name="accountabilityRelationship"
                                type="text"
                                className="margin-b20"
                                onChange={formik.handleChange}
                                value={formik.values.accountabilityRelationship}
                                error={formik.touched.accountabilityRelationship && formik.errors.accountabilityRelationship ? formik.errors.accountabilityRelationship : null}
                            />
                            <small>Where to send a message to a person</small>
                            <InputField
                                name="accountabilityMedium"
                                type="text"
                                className="margin-b20"
                                onChange={formik.handleChange}
                                value={formik.values.accountabilityMedium}
                                error={formik.touched.accountabilityMedium && formik.errors.accountabilityMedium ? formik.errors.accountabilityMedium : null}
                            />
                            <small>Phone number / Email address</small>
                            <InputField
                                name="accountabilityMediumInfo"
                                type="text"
                                className="margin-b20"
                                onChange={formik.handleChange}
                                value={formik.values.accountabilityMediumInfo}
                                error={formik.touched.accountabilityMediumInfo && formik.errors.accountabilityMediumInfo ? formik.errors.accountabilityMediumInfo : null}
                            />
                        </div>

                    )}
                    <FormControlLabel value="message-me" control={<Radio/>} className="radio-button radio-label"
                        label="Just message me directly and push me to be better"/>
                </RadioGroup>
                <Button type="submit" size="lg" className="form-button full-width margin-t20">Next</Button>
            </form>
        </SplitScreenLayout>
    );
};

export default ConsequencesSettings;