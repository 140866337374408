import React from "react";
import { Modal, ModalDialog, Typography, Button } from "@mui/joy";

const TaskDeleteModal = ({ open, handleClose, handleConfirm }) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <ModalDialog>
                <Typography variant="h6">Confirm Deletion</Typography>
                <p>Are you sure you want to delete this task?</p>
                <div style={{ textAlign: "right" }}>
                    <Button onClick={handleClose} className="form-button">Cancel</Button>
                    <Button onClick={handleConfirm} color="danger">Delete</Button>
                </div>
            </ModalDialog>
        </Modal>
    );
};

export default TaskDeleteModal;