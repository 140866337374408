import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Box, Button, Typography} from "@mui/joy";
import {Snackbar, Alert, Divider} from "@mui/material";
// import { GoogleLogin } from "react-google-login";
import { useFormik } from "formik";
import * as Yup from "yup";
// import GoogleIcon from "@mui/icons-material/Google";
import SplitScreenLayout from "../../layouts/SplitScreenLayout";
import InputField from "../../components/InputField";
import useApi from "../../hooks/useApi";
import {GoogleLogin, GoogleOAuthProvider} from "@react-oauth/google";


const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");

    const { request } = useApi();

    useEffect(() => {
        if (location.state?.message) {
            setSnackbarMessage(location.state.message);
            setSnackbarSeverity(location.state.severity || "info");
            setOpenSnackbar(true);
        }
    }, [location]);

    // const responseGoogle = (response) => {
    //     console.log(response);
    //     // Handle Google login here
    // };

    useEffect(() => {
        const checkAuth = async () => {
            const response = await request({
                url: "/validate-session", // Adjust the endpoint as needed
                method: "get"
            });

            if (response?.status === 200) {
                navigate("/dashboard?task=all");
            }
        };

        checkAuth().catch(() => {
            // Do nothing
        });
    }, []);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: ""
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email address").required("Required"),
            password: Yup.string().required("Required")
        }),
        onSubmit: async (values) => {
            // Use the request function to send login credentials to the backend
            const response = await request({
                url: "/login",
                method: "post",
                data: {
                    email: values.email,
                    password: values.password
                },
                withCredentials: true
            });

            if (response?.status === 200) {
                navigate("/dashboard?task=all");
            } else if (response?.status === 302) {
                navigate(`/onboarding/${response.data.onboardingStatus}`);
            } else {
                setSnackbarMessage("Login failed. Please try again.");
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            }
        },
    });

    const showGoogleError = () => {
        setSnackbarMessage("Login failed. Please try again.");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
    };

    const handleGoogleLoginSuccess = async (response) => {
        const tokenId = response.credential;

        try {
            const res = await request({
                url: "/auth/google", // Replace with your backend Google auth endpoint
                method: "post",
                data: { token: tokenId },
            });

            if (res?.status === 200) {
                navigate("/dashboard?task=all");
            } else if (res?.status === 302) {
                navigate(`/onboarding/${res.data.onboardingStatus}`);
            } else {
                showGoogleError();
            }
        } catch (error) {
            showGoogleError();
        }
    };

    return (
        <SplitScreenLayout title="Sign In">
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }} >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} elevation={6} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <form onSubmit={formik.handleSubmit} className="form-container contained-form">

                <GoogleOAuthProvider
                    clientId="721001096575-4lcdrsn61lplu6r4rk8aspspn48713rm.apps.googleusercontent.com">

                    <GoogleLogin
                        buttonText="Continue with Google"
                        onSuccess={handleGoogleLoginSuccess}
                        onError={showGoogleError}
                        width="400"
                        size="large"
                    />

                </GoogleOAuthProvider>

                <Divider/>
                <InputField
                    name="email"
                    type="email"
                    placeholder="Enter your email"
                    label="Email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                />
                <InputField
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                    label="Password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={formik.touched.password && formik.errors.password ? formik.errors.password : null}
                />
                <Button type="submit" size="lg" className="form-button margin-b10">Login</Button>

                <Box style={{ textAlign: "center"}}>
                    <Typography>Don’t have an account? <a style={{ color: "#11CBE5"}} href="/register">Sign up</a></Typography>
                </Box>

            </form>

        </SplitScreenLayout>
    );
};

export default Login;
