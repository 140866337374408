import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {Box, Button, Checkbox, Typography} from "@mui/joy";
// import { GoogleLogin } from "react-google-login";
import { useFormik } from "formik";
import * as Yup from "yup";
// import GoogleIcon from "@mui/icons-material/Google";
import InputField from "../../components/InputField";
import SplitScreenLayout from "../../layouts/SplitScreenLayout";
import useApi from "../../hooks/useApi";
import {Snackbar, Alert, Divider} from "@mui/material";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";

const Register = () => {
    const navigate = useNavigate();
    const { request } = useApi();

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("info");


    const handleCloseSnackbar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpenSnackbar(false);
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
            confirmPassword: "",
            acceptTerms: false,
            acceptMarketing: true,
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email address").required("Required"),
            password: Yup.string().required("Required"),
            confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match").required("Required"),
            acceptTerms: Yup.boolean()
                .required("Required")
                .oneOf([true], "You must accept the terms and conditions."),
        }),
        onSubmit: async (values) => {
            try {
                const response = await request({
                    url: "/register",
                    method: "post",
                    data: values,
                });

                if (response?.status === 201) {
                    setSnackbarMessage("Registration successful. Please check your email to validate your account.");
                    setSnackbarSeverity("success");
                    setOpenSnackbar(true);
                } else {
                    // Error Snackbar
                    setSnackbarMessage("Registration failed. Please try again.");
                    setSnackbarSeverity("error");
                    setOpenSnackbar(true);
                }
            } catch (error) {
                setSnackbarMessage("Registration error. Please try again.");
                setSnackbarSeverity("error");
                setOpenSnackbar(true);
            }
        },
    });

    const showGoogleError = () => {
        setSnackbarMessage("Login failed. Please try again.");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
    };

    const handleGoogleLoginSuccess = async (response) => {
        const tokenId = response.credential;

        try {
            const res = await request({
                url: "/auth/google", // Replace with your backend Google auth endpoint
                method: "post",
                data: { token: tokenId },
            });

            if (res?.status === 200) {
                navigate("/dashboard?task=all");
            } else if (res?.status === 302) {
                navigate(`/onboarding/${res.data.onboardingStatus}`);
            } else {
                showGoogleError();
            }
        } catch (error) {
            showGoogleError();
        }
    };



    useEffect(() => {
        const checkAuth = async () => {
            const response = await request({
                url: "/validate-session", // Adjust the endpoint as needed
                method: "get"
            });

            if (response?.status === 200) {
                navigate("/dashboard?task=all");
            }
        };

        checkAuth().catch(() => {
            // Do nothing
        });
    }, []);

    return (
        <SplitScreenLayout title="Sign Up">
            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "center" }} >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} elevation={6} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <form onSubmit={formik.handleSubmit} className="form-container contained-form">

                <GoogleOAuthProvider clientId="721001096575-4lcdrsn61lplu6r4rk8aspspn48713rm.apps.googleusercontent.com">

                    <GoogleLogin
                        buttonText="Continue with Google"
                        onSuccess={handleGoogleLoginSuccess}
                        onError={showGoogleError}
                        width="400"
                        size="large"
                    />

                </GoogleOAuthProvider>

                <Divider />


                <InputField
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="Enter your email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                    error={formik.touched.email && formik.errors.email ? formik.errors.email : null}
                />
                <InputField
                    name="password"
                    type="password"
                    label="Password"
                    placeholder="Enter your password"
                    onChange={formik.handleChange}
                    value={formik.values.password}
                    error={formik.touched.password && formik.errors.password ? formik.errors.password : null}
                />
                <InputField
                    name="confirmPassword"
                    type="password"
                    label="Confirm Password"
                    placeholder="Confirm your password"
                    onChange={formik.handleChange}
                    value={formik.values.confirmPassword}
                    error={formik.touched.confirmPassword && formik.errors.confirmPassword ? formik.errors.confirmPassword : null}
                />

                <Checkbox
                    className="margin-t20"
                    checked={formik.values.acceptTerms}
                    onChange={formik.handleChange}
                    name="acceptTerms"
                    value="yes"
                    label={<Typography sx={{ fontSize: "12px !important"}}>Accept <a href="https://taskpatrol.app/legal/terms-and-conditions.html" style={{ zIndex: 30, position: "relative"}} target="_blank" rel="noreferrer">terms and conditions</a> and our <a href="https://taskountable.com/legal/privacy-policy.html" style={{ zIndex: 30, position: "relative"}} target="_blank" rel="noreferrer">privacy policy</a></Typography>}
                />

                <Checkbox
                    checked={formik.values.acceptMarketing}
                    onChange={formik.handleChange}
                    name="acceptMarketing"
                    value="yes"
                    label={<Typography sx={{ fontSize: "12px !important"}}>Accept to receive newsletters from Task Patrol with our latest updates and offers (optional)</Typography>}
                />

                <Button type="submit" size="lg" className="form-button margin-b20">
                    Sign Up with Email
                </Button>

                <Box style={{ textAlign: "center"}}>
                    <Typography>Already have an account? <a style={{ color: "#11CBE5"}} href="/">Sign in</a></Typography>
                </Box>
            </form>
        </SplitScreenLayout>
    );

};

export default Register;
