import React, { createContext, useState, useEffect } from "react";
import useApi from "../hooks/useApi";

export const SubscriptionContext = createContext();

export const SubscriptionProvider = ({ children }) => {
    const [subscription, setSubscription] = useState(null);
    const { request } = useApi();

    const fetchSubscription = async () => {
        try {
            const response = await request({ url: "/subscription", method: "get" });
            if (response.status === 200) {
                setSubscription(response.data); // Assuming the status is in response.data.status
            }
        } catch (error) {
            console.error("Error fetching subscription status:", error);
        }
    };

    useEffect(() => {
        fetchSubscription();
    }, []);

    return (
        <SubscriptionContext.Provider value={{ subscription }}>
            {children}
        </SubscriptionContext.Provider>
    );
};
