
import React from "react";
import Task from "./Task";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";

const TaskList = ({ tasks, onAdd, onEdit, onDelete, onProofUpload }) => {
    return (
        <Box sx={{ maxWidth: "800px", margin: "auto" }}>
            {tasks.map((task) => (
                <React.Fragment key={task.id}>
                    <Task
                        task={task}
                        onEdit={onEdit}
                        onDelete={onDelete}
                        onProofUpload={onProofUpload}
                    />
                    <Divider />
                </React.Fragment>
            ))}
            <Button startIcon={<AddIcon />} onClick={onAdd}>
                Add task
            </Button>
        </Box>
    );
};

export default TaskList;
