import { useFormik } from "formik";
import * as Yup from "yup";
import {
    Modal,
    ModalDialog,
    Typography,
    ModalClose, SvgIcon, Button,
} from "@mui/joy";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import React, {useEffect, useState} from "react";
import Grid from "@mui/joy/Grid";
import { styled } from "@mui/joy";
import { v4 as uuid } from "uuid";

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const TaskProofModal = ({ open, handleClose, task = null, handleSubmit }) => {

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [key, setKey] = React.useState(0);


    const formik = useFormik({
        initialValues: {
            description: task?.proof?.description ?? "",
            files: [],
        },
        validationSchema: Yup.object({
            description: Yup.string().required("Proof is required"),
        }),
        onSubmit: () => {
            handleClose();
        },
        enableReinitialize: true,
    });

    const handleFileChange = (e) => {
        if (e.target.files) {
            let uuids = [];
            const filesArray = Array.from(e.target.files).map((file) => {
                const fileId = uuid();
                uuids.push(fileId);
                return {
                    url: URL.createObjectURL(file),
                    type: file.type,
                    id: fileId,
                };
            });

            setSelectedFiles(prevFiles => [...prevFiles, ...filesArray]);
            const files = Array.from(e.target.files);
            files.forEach((file) => {
                file.id = uuids.pop();
            });

            formik.values.files = [...formik.values.files, ...files];

            return () => filesArray.forEach((file) => URL.revokeObjectURL(file.url));
        }
    };

    useEffect(() => {
        formik.resetForm();
        setKey(Date.now());
        setKey(prevKey => prevKey + 1);
    }, [task]);


    const beforeClose = () => {
        setSelectedFiles([]);
        handleClose();
    };

    const handleRemoveFile = (fileId) => {
        setSelectedFiles(selectedFiles.filter(f => f.id !== fileId));
        formik.values.files = formik.values.files.filter(f => f.id !== fileId);


        const fileToRemove = selectedFiles.find(f => f.id === fileId);
        if (fileToRemove) {
            URL.revokeObjectURL(fileToRemove.url);
        }
    };

    const renderPreview = (file) => {
        if (file?.type?.startsWith("image/")) {
            return <img src={file.url} alt="Preview" style={{ width: "100px", height: "100px", objectFit: "cover" }} />;
        } else if (file?.type?.startsWith("video/")) {
            return <video src={file.url} style={{ width: "100px", height: "100px" }} controls />;
        }
    };

    const renderFiles = (files) => {
        return files.map((file) => (
            <div key={file?.id} className="proof-img" style={{ position: "relative", display: "inline-block", marginRight: "10px" }}>
                {renderPreview(file)}
                <button onClick={() => handleRemoveFile(file?.id)} className="remove-img-btn">X</button>
            </div>
        ));
    };

    const handleEditorChange = (content, delta, source, editor) => {
        formik.setFieldValue("description", editor.getHTML());
    };

    return (
        <Modal open={open} onClose={beforeClose}>
            <ModalDialog sx={(theme) => ({
                minWidth: "900px",
                padding: "0",
                [theme.breakpoints.down("md")]: {
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    borderRadius: 0,
                    transform: "none",
                    maxWidth: "unset",
                    minWidth: "100%",
                    minHeight: "100vh",
                },
            })}>
                <ModalClose />
                <Typography style={{ paddingLeft: "15px", marginTop: "15px" }} variant="h3">{task?.proof?.description !== null || task?.proof?.attachments.length ? "Edit Proof" : "Add Proof"}</Typography>
                <form style={{borderTop: "1px solid #dedede"}} onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(formik.values);
                }}>
                    <Grid container>
                        <Grid xs={12} md={8} className="modal-content">
                            <small>Proof description</small>
                            <ReactQuill key={key} style={{height: "300px", paddingBottom: "50px"}} name="description"
                                dangerouslySetInnerHTML={{ __html: formik.values.description }}
                                label="Description" className="margin-b20 margin-t10" theme="snow"
                                defaultValue={formik.values.description} onChange={handleEditorChange}/>
                        </Grid>
                        <Grid xs={12} md={4} className="modal-sidebar">
                            <small className="margin-b10">Attachments</small><br />
                            <Button
                                component="label"
                                role={undefined}
                                tabIndex={-1}
                                variant="solid"
                                className="primary-btn"
                                startDecorator={
                                    <SvgIcon>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            strokeWidth={1.5}
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M12 16.5V9.75m0 0l3 3m-3-3l-3 3M6.75 19.5a4.5 4.5 0 01-1.41-8.775 5.25 5.25 0 0110.233-2.33 3 3 0 013.758 3.848A3.752 3.752 0 0118 19.5H6.75z"
                                            />
                                        </svg>
                                    </SvgIcon>
                                }
                            >
                            Upload a file
                                <VisuallyHiddenInput type="file" multiple onChange={handleFileChange}/>
                            </Button>
                            <div className="preview">
                                {renderFiles(selectedFiles)}
                            </div>
                            <Button type="submit" size="md" className="form-button margin-t20 add-task-btn">
                                {task?.proof.description !== null || task?.proof.attachments.length ? "Update Proof" : "Submit Proof"}
                            </Button>
                        </Grid>
                    </Grid>
                </form>


            </ModalDialog>
        </Modal>
    );
};

export default TaskProofModal;